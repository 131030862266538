<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Variantengruppe</h4>
                            <p>Variantengruppe editieren</p>
                        </div>
                    </div>
                    <div class="card-body">
                        <form ref="form" @submit.stop.prevent="handleSubmit">
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="control-label col-sm-2 col-form-label col-form-label-sm">Name</label>
                                        <div class="col-sm-9">
                                            <input v-model="form.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}" tabindex="1" disabled />
                                            <has-error :form="form" field="name"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="control-label col-sm-2 col-form-label col-form-label-sm">Beschreibung</label>
                                        <div class="col-sm-9">
                                            <input v-model="form.description" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('description')}" tabindex="2" disabled/>
                                            <has-error :form="form" field="description"></has-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Variantenoptionen</h4>
                            <p>Liste der Variantenoptionen</p>
                        </div>
                    </div>
                    <div class="card-body">
                        <b-row class="mb-3">
                            <b-col md="3">
                                <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table striped hover outlined :fields="fields" :items="variationValues" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">

                                </b-table>
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="card-footer">

                    </div>
                </div>
            </div>
        </div>


    </b-container>
</template>

<script>
export default {
    name: "VariationGroupDetail",
    title: "Variantengruppe bearbeiten",

    data() {
        return {
            form: new window.Form({
                id: "",
                name: "",
                description: "",
            }),
            valueForm: new window.Form({
                id: "",
                name: "",
            }),
            variantionGroup: {},
            variationValues: [],
            editMode: false,
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            fields: [
                {key: "id", sortable: true},
                {key: "name", label: "Name", sortable: true},
                {key: "actions", label: "Actions", tdClass: 'hls_action_column'},
            ],
            editModeValue: false,
            modalTitle: '',
        }
    },

    methods: {
        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editModeValue == true)
            {
                //Edit User
                this.editVariationValue(id);
            }
            else
            {
                //Create User
                this.createVariationValue();
            }
        },

        createModal() {
            this.modalTitle = "Variantenoption anlegen";
            this.valueForm.clear();
            this.valueForm.reset();
            this.editModeValue = false;
            this.$bvModal.show("VariationValueModal");
        },

        editModal(Value) {
            this.editModeValue = true;
            this.modalTitle = "Variantenoption bearbeiten";
            this.valueForm.reset();
            this.valueForm.fill(Value);
            this.$bvModal.show("VariationValueModal");

        },

        editModeOn() {
            this.editMode = true;
        },

        editModeOff() {
            this.form.reset();
            this.form.fill(this.variantGroup);
            this.editMode = false;
        },

        async editVariationGroup(){
            this.$Progress.start();
            this.form
                .put("/variations/"+this.variantGroup.id)
                .then(() => {
                    this.loadVariationGroup();
                    this.editModeOff();
                    this.$swal({
                        icon: "success",
                        title: "Variantengruppe wurde editiert",
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        async loadVariantionGroup(){
            this.$Progress.start();
            await this.axios
                .get("/variations/" + this.$route.params.id)
                .then((response) => {
                    this.variationGroup = response.data.data;
                    this.form.fill(this.variationGroup);
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        async loadVariationValues(){
            this.$Progress.start();
            await this.axios
                .get("/variations/" + this.$route.params.id + '/values')
                .then((response) => {
                    this.variationValues = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        async createVariationValue(){
            this.$Progress.start();
            this.valueForm
                .post("/variations/" + this.$route.params.id + "/values")
                .then(() => {
                    this.$bvModal.hide("VariationValueModal");
                    this.$swal({
                        icon: "success",
                        title: "Variantenoption wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadVariationValues();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        async editVariationValue(id){
            this.$Progress.start();
            this.valueForm
                .put("/variations/" + this.$route.params.id + "/values/" + id)
                .then(() => {
                    this.$bvModal.hide("VariationValueModal");
                    this.$swal({
                        icon: "success",
                        title: "Variantenoption wurde editiert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadVariationValues();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        async deleteVariationValue(id){
            await this.$swal({
                title: "Möchtest du die Berechtigung wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.valueForm
                        .delete("/variations/" + this.$route.params.id + "/values/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Variantenoption erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadVariationValues();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        }
    },

    created() {
        this.loadVariantionGroup();
        this.loadVariationValues();
    },

    computed: {
        tableData() {
            return this.variationValues || [];
        },

        rows() {
            return this.variationValues.length;
        },
    },




}
</script>

<style>

</style>